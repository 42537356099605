/* App Header */
.app-header {
	background: #667585;
	padding: 10px;
	box-shadow: 0 5px 4px 0 rgba(43, 44, 80, 0.07);
	align-self: center;
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
}
.app-header h1 {
	margin: 0;
	min-height: 51px;
	padding: 10px 10px 9px 10px;
	font-family: 'Muli', sans-serif;
	font-size: 40px;
	font-weight: 300;
	text-transform: uppercase;
	font-size: 25px;
	color: #ffffff;
	letter-spacing: 1px;
}

.logged-in-user-details {
	color: #ffffff;
	margin-right: 20px;
}
.sub-header h3 {
	margin: 0;
}
