.side-drawer {
	background-color: #191935;
	background-image: linear-gradient(-3deg, #2b2c50 0%, #191935 95%);
}
.side-drawer {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 72px 1fr auto;
}

.side-drawer .logo {
	padding: 9px 20px 8px 20px;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.5);
	border-bottom: 1px solid #e5a03e;
}
.side-drawer .logo img {
	max-height: 50px;
}

.main-nav ul {
	list-style-type: none;
}

.main-nav ul,
.main-nav li,
.main-nav a {
	margin: 0;
	padding: 0;
}

.main-nav a {
	text-decoration: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	display: grid;
	grid-template-columns: 25px 1fr 25px;
	font-family: 'Source Sans Pro', sans-serif;
	color: #fff;
	font-weight: 300;
	text-transform: uppercase;
	padding: 15px 10px;
	transition: all 0.2s;
	align-items: center;
}

.main-nav a:hover {
	background: #3b4170;
}
.main-nav a:hover svg {
	color: #fff;
}
.main-nav svg {
	color: #fff;
}

.product-of h3 {
	background: rgba(0, 0, 0, 0.2);
	padding: 10px;
	margin: 0;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 10px;
	font-weight: 300;
}
.product-of a {
	background: rgba(0, 0, 0, 0.15);
	display: block;
	padding: 15px 10px 10px 10px;
	margin: 0;
	text-align: center;
}
.product-of a img {
	max-height: 40px;
}
.version {
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	padding: 10px;
	margin: 0;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 10px;
	font-weight: 300;
}
