.nav-split {
	border-bottom: 1px solid #f5be58;
	padding: 10px;
	margin: 30px 0 0 0;
	text-align: center;
	color: #f5be58;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 10px;
	font-weight: 300;
}

/*  Sub-Menu  */
.sub-menu {
	max-height: 0;
	border-left: 3px solid #f5be58;
	overflow: hidden;
	transition: all 0.5s;
}
.side-drawer [aria-expanded='true'] {
	transition: all 0.5s;
	border-left: 3px solid #f5be58;
}
.side-drawer [aria-expanded='true'] ~ ul {
	display: block;
	max-height: 500px;
	transform: rotateX(0);
}
/* Arrow */
.navigation-item .arrow {
	transition: all 0.5s;
	padding: 0;
	color: #fff;
}
.side-drawer [aria-expanded='true'] .arrow {
	transform: rotate(180deg);
}
.side-drawer [aria-expanded='true']:hover .arrow {
	color: #fff;
}
.side-drawer [aria-expanded='false'] .arrow {
	transform: rotate(0deg);
}
/* Item */
.navigation-item .active {
	background: #e5a03e !important;
	color: #060921;
}
.side-drawer [aria-expanded='true'] {
	color: #fff;
	background: #3b4170;
}
.side-drawer [aria-expanded='true'] ~ .sub-menu {
	background: #060921;
}
.side-drawer [aria-expanded='true'] ~ .sub-menu a {
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.side-drawer .sub-menu a:hover {
	color: #f5be58;
}
.side-drawer .sub-menu a.active:hover {
	color: #060921;
}
.navigation-item a.active svg {
	color: #060921;
}
.navigation-item .sub-menu {
	background: rgba(25, 25, 53, 0.1);
}
.navigation-item .sub-menu .active,
.navigation-item .sub-menu a:hover {
	background: rgba(25, 25, 53, 0.7);
}

.nav-icon {
	cursor: pointer;
	margin: 0;
	padding: 0;
	width: 30px;
	height: 25px;
	position: relative;
	transform: rotate(0deg);
	transition: 0.5s ease-in-out;
}
.nav-icon span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background: #fff;
	border-radius: 10px;
	opacity: 1;
	left: 0;
	transform: rotate(0deg);
	transition: 0.25s ease-in-out;
}
.nav-icon span:nth-child(1) {
	top: 0px;
}
.nav-icon span:nth-child(2),
.nav-icon span:nth-child(3) {
	top: 10px;
}
.nav-icon span:nth-child(4) {
	top: 20px;
}
.nav-icon.open span:nth-child(1) {
	top: 10px;
	width: 0%;
	left: 50%;
}
.nav-icon.open span:nth-child(2) {
	transform: rotate(45deg);
}
.nav-icon.open span:nth-child(3) {
	transform: rotate(-45deg);
}
.nav-icon.open span:nth-child(4) {
	top: 10px;
	width: 0%;
	left: 50%;
}
