.error-boundary-wrapper {
	display: grid;
	grid-template-columns: 1fr;
	width: 100vw;
	height: 100vh;
	align-content: center;
	justify-content: center;
}
.error-boundary-box {
	background: #fde49e url('/assets/icons/icon-warn.svg') no-repeat center 15px;
	border: 1px solid #ce7c3a;
	padding: 55px 15px 15px 15px;
	border-radius: 10px;
	max-width: 400px;
	margin: 0 auto;
	text-align: center;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07),
		0 15px 35px 0 rgba(50, 50, 93, 0.07);
}
.error-boundary-box h1 {
	font-size: 20px;
	font-family: 'Muli', sans-serif;
	color: #7b3c08;
	line-height: 30px;
}
.error-boundary-box a {
	color: #7b3c08;
	background: rgba(255, 255, 255, 0.3);
	padding: 3px;
	border-radius: 5px;
}
