body {
	margin: 0;
	padding: 0;
	font-family: 'Source Sans Pro', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Muli', sans-serif;
}

/********************** volumes/types/couple/styles *********************************/

.volumes {
	border: 1px solid #d8d8d8;
	border-radius: 1rem;
	/* padding: 1rem; */
	flex: 1;
	height: fit-content;
	margin-right: 1rem;
	overflow: hidden;
	position: relative;
}
.add_button {
	position: absolute;
	bottom: -1rem;
	right: 2rem;
	height: 1.5rem;
	width: 1.5rem;
	background-color: #667586;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}
.volumes h3 {
	background-color: #667586;
	text-align: center;
	color: white;
	padding: 0.5rem 0rem;
}

.volumes input:focus {
	outline: none;
}
.volumes_container {
	display: flex;
	justify-content: space-around;
}

.volumes .volume_list {
	padding: 0.2rem 0.5rem;
	border-bottom: 1px solid #d8d8d8;
	/* padding-top: 0.2rem;
	padding-bottom: 0.2rem; */
	display: flex;
	justify-content: space-between;
}

.vol_buttons {
	display: flex;
	justify-content: space-around;
	margin-bottom: 1rem;
}
.vol_buttons button {
	border: none;
	cursor: pointer;
	padding: 0.5rem 2rem;
	border-radius: 1rem;
	background-color: #667586;
	font-weight: 600;
	color: whitesmoke;
}

.vol_buttons button:hover {
	background-color: #efefef;
}
.page-bg {
	padding: 20px;
	background: #ffffff;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
	border-radius: 5px;
}
.widget {
	padding: 20px;
	background: #ffffff;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
	border-radius: 5px;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.widget.quick-links a {
	color: var(--dark) !important;
	transition: all 0.2s ease-in-out;
}
.widget.quick-links a:hover {
	color: var(--yellow-dark) !important;
}
.widget.margin-bottom {
	margin-bottom: 30px;
}
.ui.table tfoot tr th {
	font-weight: 700;
}
:root {
	--yellow-light: #fdd760;
	--yellow-dark: #e5a03e;
	--grey-1: #f6f9fc;
	--grey-2: #d6dce2;
	--grey-3: #aab7c4;
	--grey-4: #8898aa;
	--purple1: #2b2c50;
	--dark: #191935;
	--placeholder: #edf0f3;
}
h2.full-width {
	background: var(--dark);
	color: #fff;
	margin: 0;
	padding: 15px 20px;
}
img {
	max-width: 100%;
	height: auto;
}
.page-padding a {
	color: #e5a03e;
}
.page-padding a:hover {
	color: #191935;
}
body i.grey.icon {
	color: var(--grey-3) !important;
}
body .ui.grey.button,
body .ui.grey.buttons .button {
	background-color: var(--grey-3) !important;
}
body .ui.grey.button:hover,
body .ui.grey.buttons .button:hover {
	background-color: var(--grey-4) !important;
}
body .ui.basic.grey.button,
body .ui.basic.grey.buttons .button {
	background: transparent none !important;
}
body {
	background: #f6f9fc url('/assets/images/bg-decal.png') no-repeat right bottom !important;
	background-size: 500px !important;
	color: var(--dark);
	height: auto !important;
}
body .ui[class*='right labeled'].icon.button {
	padding-right: 3.6em !important;
	padding-left: 1.3em !important;
}

.app-container {
	display: grid;
	grid-template-columns: 215px 1fr;
	min-height: 100vh;
}

.page-padding {
	padding: 30px;
}
.page-margin {
	margin: 30px;
}
.no-padding {
	padding: 0 !important;
}

.table {
	background: #fff;
	width: 100%;
}

.sub-header {
	background: var(--grey-2);
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	padding: 13px 20px;
	margin: 0 !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.sub-header h3 {
	font-weight: 300;
}
.sub-header svg {
	padding-right: 10px;
}
.sub-header-txt {
	background: #fff;
	margin-bottom: 20px;
	padding: 15px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}

.roles-labels .ui.label {
	margin: 3px !important;
	min-width: 105px;
	text-align: left;
	display: inline-block;
}
.coupler {
	text-transform: uppercase;
	background: var(--grey-2);
	padding: 4px;
	margin: 5px;
	display: inline-block;
	border-radius: 4px;
	min-width: 30px;
	text-align: center;
}
.label-value-small {
	text-transform: uppercase;
	background: var(--grey-2);
	margin: 5px;
	font-size: 12px;
	display: grid;
	grid-template-columns: 2fr minmax(30px, 1fr);
	justify-content: center;
	align-content: center;
	border-radius: 4px;
	min-width: 30px;
	text-align: center;
	white-space: pre-wrap;
}
.label-value-small .left {
	padding: 4px;
}
.label-value-small .right {
	padding: 4px;
	font-size: 14px;
	font-weight: 700;
	align-self: stretch;
	background: var(--yellow-light);
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.company-names-small-block {
	display: block;
	padding: 5px;
	color: var(--dark);
}
.company-names-small-block:hover {
	color: var(--yellow-dark);
}

.label-small {
	background: rgba(0, 0, 0, 0.08);
	padding: 5px;
	border-radius: 3px;
	text-transform: uppercase;
	font-size: 10px;
	display: inline-block;
	margin: 3px;
}
.ui.basic.red.label {
	background: #fff !important;
}

.company-logo {
	padding: 0px !important;
}

.ui.segment.wrapper {
	padding: 0 !important;
	border: none;
	box-shadow: 0 5px 15px 0 rgba(36, 36, 39, 0.1);
}
.ui.orange.basic.button.delete:hover {
	background: #d64f1b !important;
	color: #fff !important;
}

.ui.red.basic.button.actions:hover {
	background: #db2828 !important;
	color: #fff !important;
}

.ui.green.basic.button.actions:hover {
	background: #21ba45 !important;
	color: #fff !important;
}

.ui.orange.basic.button.delete.left {
	text-align: left;
	padding: 13px 10px;
	border-radius: 5px;
}
.rrt-ok-btn {
	color: #d64f1b;
}
.rrt-confirm-holder
	.rrt-confirm
	.rrt-buttons-holder
	.rrt-button.rrt-ok-btn:active {
	background-color: #d64f1b !important;
	color: white;
}
.rrt-confirm-holder
	.rrt-confirm
	.rrt-buttons-holder
	.rrt-button.rrt-cancel-btn:active {
	background-color: #ccc !important;
	color: #333 !important;
}
.rrt-message:before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: '\f071';
	color: #d64f1b;
	padding: 0 0 10px 0;
	font-size: 20px;
	display: block;
}
.rrt-message {
	background: #d6501b1c !important;
}
.recent-activity .content {
	padding: 0 !important;
}
.recent-activity .header {
	padding: 1em;
}
.recent-activity .event {
	border-bottom: 1px solid #f2f2f2;
	padding: 12px 12px 8px 12px !important;
	margin-bottom: 10px !important;
}
.recent-activity .event:last-child {
	border: none;
	margin: 0;
	padding-bottom: 0 !important;
}
.no-content-message:before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: '\f071';
	color: #ce7c3a;
	padding: 0 20px 10px 0;
	font-size: 20px;
}
.no-content-message {
	padding: 20px;
	display: block;
}
.address-line-small {
	font-size: 12px;
	padding: 0;
	margin: 0;
	line-height: 14px;
}
.address-line-small.inline {
	display: inline;
}
.roleContainer.inline {
	display: inline;
}
.roleContainer.block {
	display: block;
}
.space-small {
	height: 10px;
}
.inline {
	display: inline-block;
}
.sub-header.inpage {
	border-radius: 5px;
	margin-bottom: 20px;
}
.message-success {
	background: #cffbd4 url('/assets/icons/icon-success.svg') no-repeat 10px
		center;
	display: block;
	border: 1px solid #159570;
	padding: 15px 15px 15px 55px;
	border-radius: 5px;
	margin: 14px;
	color: #159570;
	width: 100%;
}
.message-info {
	background: #a5e6fb82 url('/assets/icons/icon-info.svg') no-repeat 10px center;
	display: block;
	border: 1px solid #abd0ea;
	padding: 15px 15px 15px 55px;
	border-radius: 5px;
	margin: 14px;
	color: #227ab7;
	width: 100%;
}
.client-dashboard-graph .message-info {
	margin: 50px 0;
}
.message-warn {
	background: #fde49e url('/assets/icons/icon-warn.svg') no-repeat 10px center;
	display: block;
	border: 1px solid #ce7c3a;
	padding: 15px 15px 15px 55px;
	border-radius: 5px;
	margin: 0;
	color: #ce7c3a;
	width: 100%;
}
.time-label {
	padding: 5px;
	border-radius: 3px;
}
.time-label.warning {
	color: #633f08;
	background: #ff9800c7;
}
.time-label.danger {
	color: #521908;
	background: #dc4515;
}
.keg-quick-view-page .message-warn {
	margin-left: 14px;
	margin-right: 14px;
}
p .icon {
	margin-right: 0.3em !important;
}
.ui.form > p.message-info {
	margin-top: 0 !important;
}
.interaction-list-more {
	text-align: center;
	color: #bbb;
	margin: 5px 5px 20px 5px;
	padding: 0;
}
.interaction-list-more p {
	margin: 0;
	padding: 0;
}
.side-panel-button {
	background: #fff;
	border: 1px solid #a7b4c6;
	color: var(--dark) !important;
	padding: 10px;
	text-align: left;
	display: block;
	border-radius: 5px;
	transition: all 0.2s ease-in-out;
	margin-bottom: 12px;
}
.side-panel-button[aria-current='page'] {
	background: #d2d5da;
	border: 1px solid #7f8c9c;
}
.side-panel-button:hover {
	color: var(--dark);
	border: 1px solid #7f8c9c !important;
}
.ui.buttons .button.in-form-archive {
	margin-top: -37px;
	z-index: 99999;
}
.placeholder-group {
	display: block;
}
.placeholder-line {
	background: var(--placeholder);
	height: 8px;
	margin: 1px 0 !important;
	display: inline-block;
}
.placeholder-line.w100 {
	width: 100%;
}
.placeholder-line.w90 {
	width: 90%;
}
.placeholder-line.w80 {
	width: 80%;
}
.placeholder-line.w70 {
	width: 70%;
}
.placeholder-line.w60 {
	width: 60%;
}
.placeholder-line.w50 {
	width: 50%;
}
.placeholder-line.w40 {
	width: 40%;
}
.placeholder-line.w30 {
	width: 30%;
}
.placeholder-line.w20 {
	width: 20%;
}
.placeholder-line.w10 {
	width: 10%;
}
.pull-right {
	float: right;
}
.scanner-image {
	width: 70%;
	max-width: 300px;
	display: block;
	margin: 50px auto;
}
.text-center {
	text-align: center;
}
.collectKegIds {
	margin: 0 0 15px 0;
	display: flex;
	align-content: space-between;
	width: 100%;
}
.collectKegIds .ui.input {
	width: 60%;
	margin-right: 5%;
}
.collectKegIds .ui.button {
	width: 35%;
}
.collectKegIds .ui.input {
	width: 100% !important;
	margin-right: 0 !important;
}
.keg-fill-column-center {
	border-left: 1px solid #e9eaea;
	border-right: 1px solid #e9eaea;
}
.keg-selector-page .ui.label {
	margin: 5px !important;
}
.keg-quick-view-page .wrapper {
	min-height: 500px;
}
.keg-quick-view-page .wrapper .row {
	padding-top: 0 !important;
}
.keg-view-details {
	margin: 14px -14px 0 14px;
}
.two-col-last .col-inner {
	padding-right: 40px;
	padding-top: 15px;
}
.two-col-last {
	border-left: 1px solid #e9eaea;
}
.two-col-last h3 {
	border-top-right-radius: 5px;
	margin-right: 0 !important;
}
.grid-padding {
	padding: 10px 10px 10px 20px;
}
.grid-padding.end {
	padding: 10px 20px 10px 10px;
}
.inform-heading {
	background: var(--grey-2);
	padding: 10px;
	text-align: center;
	margin: 0 -13px 10px -13px !important;
}
.inform-heading.start {
	margin-left: 0 !important;
	border-top-left-radius: 5px;
}
.inform-heading.end {
	margin-right: 0 !important;
	border-top-right-radius: 5px;
}
.keg-selector-page .ui.segment:first-child {
	margin: 1rem 0 !important;
}
.keg-selector-page .ui[class*='vertically divided'].grid > .row {
	padding: 0 !important;
}
.illustration {
	width: 400px;
	margin: 30px;
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 46px;
}
.lds-ellipsis div {
	position: absolute;
	top: 27px;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background: var(--yellow-light);
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.grey .lds-ellipsis div {
	background: rgba(0, 0, 0, 0.1);
}
.lds-ellipsis div:nth-child(1) {
	left: 6px;
	animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
	left: 6px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
	left: 26px;
	animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
	left: 45px;
	animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(19px, 0);
	}
}
.block-center {
	display: block;
	text-align: center;
}
/* ERROR */
.batch-complete-text .bulk-error h2 {
	color: #8c202e;
	text-align: center;
}
/* SUCCESS */
.batch-complete-text .bulk-success h2 {
	color: #24b47e;
	text-align: center;
}
.ui.green.button,
.ui.green.buttons .button {
	background-color: #24b47e !important;
}
.bulk-error,
.bulk-success {
	text-align: center;
}
.batch-complete-text p {
	color: var(--dark);
	margin: 50px;
	text-align: center;
}
.small-label-right {
	width: 48px;
	display: inline-block;
	padding-right: 10px;
	text-align: right;
}
.medium-label-right {
	width: 65px;
	display: inline-block;
	padding-right: 10px;
	text-align: right;
}
.list-of-available-kegs .button {
	margin: 3px !important;
}

/* REPORTS */
.report-wrapper {
	display: grid;
	grid-template-columns: 1fr 300px;
	transition: all 0.3s ease-in-out;
}
.report-nav-drawer {
	background: #aab7c4;
	margin: 0;
	padding: 20px;
	transition: all 500ms ease;
	min-height: calc(100vh - 71px);
}
.report-nav-drawer a {
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07),
		0 15px 35px 0 rgba(50, 50, 93, 0.07);
	box-sizing: border-box;
	transition: all 200ms ease;
	display: grid;
	grid-template-columns: 50px 1fr;
	position: relative;
	margin-bottom: 20px;
}
.report-nav-drawer a span {
	color: rgba(0, 0, 0, 0.4);
	font-size: 10px;
}
.report-nav-drawer svg {
	margin: 5px;
	max-width: 20px;
	max-height: 20px;
	justify-self: center;
	align-self: center;
	color: #2b2c50;
	transition: all 500ms ease;
}
.report-nav-drawer h3 {
	font-family: 'Muli', sans-serif;
	font-size: 14px;
	background: #f6f9fc;
	padding: 10px;
	min-height: 55px;
	margin: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	text-transform: uppercase;
	font-weight: 300;
	color: var(--dark);
	letter-spacing: 0.3px;
	text-align: left;
	transition: all 500ms ease;
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
}

.report-nav-drawer a[aria-current='page'] {
	background: #3b4170;
}
.report-nav-drawer a[aria-current='page'] span {
	color: rgba(255, 255, 255, 0.4);
}
.report-nav-drawer a[aria-current='page']:after {
	content: '';
	width: 0;
	height: 0;
	border-top: 14px solid transparent;
	border-bottom: 14px solid transparent;
	position: absolute;
	left: -10px;
	top: calc(50% - 14px);
	border-right: 12px solid #3b4170;
}
.report-nav-drawer a[aria-current='page'] svg {
	color: #fff;
}
.report-nav-drawer a[aria-current='page'] h3 {
	background: #2b2c50;
	color: #fff;
}

.report-nav-drawer a:hover {
	box-shadow: 5px 15px 25px 0 rgba(50, 50, 93, 0.07),
		10px 25px 25px 0 rgba(50, 50, 93, 0.07);
	transform: translateX(-5px);
}

/* KEG LIST */
.keg-list-nav-drawer {
	background: #aab7c4;
	margin: 0;
	padding: 20px;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 20px;
	transition: all 500ms ease;
}
.keg-status-card a {
	border-radius: 5px;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07),
		0 15px 35px 0 rgba(50, 50, 93, 0.07);
	text-align: center;
	box-sizing: border-box;
	transition: all 500ms ease;
	position: relative;
	display: block;
}
.keg-status-card a:hover {
	box-shadow: 5px 15px 25px 0 rgba(50, 50, 93, 0.07),
		10px 25px 25px 0 rgba(50, 50, 93, 0.07);
	transform: translateY(-5px);
}
.keg-list-nav-drawer h3.title {
	font-family: 'Muli', sans-serif;
	font-size: 16px;
	padding: 10px;
	margin: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	text-transform: uppercase;
	font-weight: 300;
	letter-spacing: 0.3px;
	text-align: center;
	transition: all 500ms ease;
	background: #3b4170;
}
/* Active Arrow */
.keg-list-nav-drawer a[aria-current='page']:after {
	content: '';
	width: 0;
	height: 0;
	border-left: 14px solid transparent;
	border-right: 14px solid transparent;
	position: absolute;
	bottom: -10px;
	left: calc(50% - 14px);
}

/* Active = All */
.keg-list-nav-drawer .all a[aria-current='page']:after {
	border-top: 12px solid #2b2c50;
}
.keg-status-card.all .active .ui.statistic {
	background: #3b4170;
}
.keg-status-card.all .active .ui.statistic .value {
	color: #fff !important;
}
.keg-list-nav-drawer .all h3.title {
	background: #2b2c50;
}

/* Active = New */
.keg-list-nav-drawer .new a[aria-current='page']:after {
	border-top: 12px solid #fbbd08;
}
.keg-status-card.new .active .ui.statistic {
	background: #ffcc31;
}
.keg-list-nav-drawer .new h3.title {
	background: #fbbd08;
	color: rgba(0, 0, 0, 0.7);
}

/* Active = Empty */
.keg-list-nav-drawer .empty a[aria-current='page']:after {
	border-top: 12px solid #9a5427;
}
.keg-status-card.empty .active .ui.statistic {
	background: #a5673f;
}
.keg-status-card.empty .active .ui.statistic .value {
	color: #fff !important;
}
.keg-list-nav-drawer .empty h3.title {
	background: #9a5427;
}

/* Active = Warehouse */
.keg-list-nav-drawer .warehouse a[aria-current='page']:after {
	border-top: 12px solid #b5cc18;
}
.keg-status-card.warehouse .active .ui.statistic {
	background: #c8e027;
}
.keg-list-nav-drawer .warehouse h3.title {
	background: #b5cc18;
	color: rgba(0, 0, 0, 0.7);
}

/* Active = Dispatched */
.keg-list-nav-drawer .dispatched a[aria-current='page']:after {
	border-top: 12px solid #00b5ad;
}
.keg-status-card.dispatched .active .ui.statistic {
	background: #07c9bf;
}
.keg-status-card.dispatched .active .ui.statistic .value {
	color: #fff;
}
.keg-list-nav-drawer .dispatched h3.title {
	background: #00b5ad;
	color: #fff;
}

/* Active = Dispatched */
.keg-list-nav-drawer .issue a[aria-current='page']:after {
	border-top: 12px solid #db2828;
}
.keg-status-card.issue .active .ui.statistic {
	background: #e73c3c;
}
.keg-status-card.issue .active .ui.statistic .value {
	color: #fff;
}
.keg-list-nav-drawer .issue h3.title {
	background: #db2828;
	color: #fff;
}

.sub-drawer-header {
	background: #94a7bc;
	padding: 15px 30px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 300;
	font-family: 'Muli', sans-serif;
}

/* DASHBOARD */
.dashboard-keg-status-drawer {
	background: #aab7c4;
	margin: 0;
	padding: 20px;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 20px;
	transition: all 500ms ease;
}
.dashboard-keg-status-drawer.stats {
	grid-template-columns: repeat(5, 1fr);
}
.keg-status-card a {
	border-radius: 5px;
	box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07),
		0 15px 35px 0 rgba(50, 50, 93, 0.07);
	text-align: center;
	box-sizing: border-box;
	transition: all 500ms ease;
	position: relative;
}
.keg-status-card.dashboard .description {
	background: #f6f9fc;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}
.keg-status-card .ui.statistic {
	background: #fff;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	font-family: 'Muli', sans-serif;
	font-size: 40px;
	line-height: 50px;
	padding: 20px;
	display: block;
	margin: 0;
}
.keg-status-card .ui.statistic .value {
	color: var(--dark);
}
.keg-status-card .title {
	background: var(--dark);
	font-family: 'Muli', sans-serif;
	color: #fff;
	display: block;
	padding: 5px;
	font-size: 14px;
	text-transform: uppercase;
}
.dashboard-keg-status-drawer.stats .keg-status-card .title {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	text-align: center;
}
.keg-status-card.new .title {
	background: #fbbd08;
}
.keg-status-card.empty .title {
	background: #a5673f;
}
.keg-status-card.warehouse .title {
	background: #b5cc18;
}
.keg-status-card.dispatched .title {
	background: #00b5ad;
}
.keg-status-card.issue .title {
	background: #db2828;
}
.keg-status-card .description {
	font-family: 'Muli', sans-serif;
	color: var(--dark);
	font-size: 12px;
	line-height: 14px;
	padding: 10px 20px;
	margin: 0;
	display: block;
}
.dashboard .widget {
	min-height: 255px;
}
.dashboard .basic.table {
	width: 100% !important;
}
.dashboard .basic.table .label {
	width: 35%;
	font-weight: 700;
}
.dashboard .basic.table .value {
	text-align: center;
}
.dashboard .basic.table .actions {
	text-align: right;
	width: 45%;
}
.widget-table-header {
	display: grid;
	grid-template-columns: 1fr 120px;
	justify-items: end;
	align-items: center;
	border-bottom: 1px solid #eee;
	padding-bottom: 15px;
}
.widget-table-header h2 {
	justify-self: start;
	margin: 0;
}
.widget .ui.basic.table {
	margin-top: 0;
}
.widget .ui[class*='very compact'].table th {
	padding: 10px !important;
}
.side-drawer .main-nav {
	transition: all 0.2s ease-in-out;
}
.mobile-nav-trigger {
	display: none;
}
.side-drawer-header {
	background: rgba(0, 0, 0, 0.08);
}
.text-uppercase {
	text-transform: uppercase !important;
}
.text-capitalize {
	text-transform: capitalize;
}
.stats td svg {
	width: 30px !important;
}

.keg-selector-page .ui.form .button.big {
	position: fixed !important;
	bottom: 0;
	right: 0;
	z-index: 9999;
	opacity: 1 !important;
	width: calc(100% - 215px);
	margin: 0;
	border-radius: 0;
	padding: 20px;
	box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.2);
}
.keg-selector-page .ui.form .button.big.disabled {
	cursor: not-allowed;
	background: #8898aa;
}
.keg-selector-page .ui.form .button.big {
	background: #e5a03e;
}
.ui.black.sub.header {
	font-weight: 700;
	font-size: 18px;
}
.segment .ui.divider {
	margin: 0 0 15px 0;
}
.keg-selector-page {
	margin-bottom: 70px;
}
.fineprint {
	font-size: 12px;
	font-style: italic;
}
.fineprint span {
	background: #eee;
	padding: 3px 5px;
}
.staging-label {
	background: #555abf;
	color: #fff;
	text-align: center;
	padding: 8px 12px;
	text-transform: uppercase;
}

.admin-label {
	background: #e5a03e;
	color: #2b2c50;
	border-bottom: 1px solid #2b2c50;
	text-align: center;
	padding: 8px 12px;
	text-transform: uppercase;
}

.hideDesktop {
	display: none;
}

.ui.basic.table.brands-table tbody tr:nth-child(2n) {
	background: var(--grey-1) !important;
}
.positive,
.value.positive {
	font-weight: 700;
}
.zero {
	color: rgba(0, 0, 0, 0.5) !important;
}
.brands-table .label {
	padding-left: 7px !important;
}
.info-panel {
	background: #e5f1fe;
	border-bottom: 1px solid #bccfe4;
	padding: 20px;
	color: #202c74;
}
.info-panel span {
	background: #bccfe4;
	border-radius: 3px;
	font-weight: 700;
	padding: 3px 6px;
}
.suggestion-not-found {
	background: #e5f1fe;
	border: 1px solid #bccfe4;
	padding: 10px 15px;
	margin-top: 10px;
	border-radius: 5px;
	color: #202c74;
}
.suggestion-not-found span {
	background: #bccfe4;
	border-radius: 3px;
	font-weight: 700;
	padding: 3px 6px;
	margin: 0 5px;
}
.activity-log-list .item.info {
	color: #9ad0e2;
}
.activity-log-list .item.success {
	color: #24b47e;
}
.activity-log-list .item.danger {
	color: #8c202e;
}
.activity-log-list .content {
	width: 100% !important;
}
.activity-log-list .content .header {
	float: left;
}
.activity-log-list .content .description {
	float: right;
}
.brands-table .value {
	min-width: 125px;
}
.brewery-totals-table {
	width: 100% !important;
}
.ui.dropdown .menu > .item > .description,
.ui.dropdown > .text > .description {
	text-transform: uppercase;
	font-size: 10px;
	background: rgba(0, 0, 0, 0.08);
	padding: 3px 6px;
	border-radius: 4px;
	min-width: 60px;
	text-align: center;
}
.dashboard-keg-count-trigger:hover {
	background: #fbbd08;
	color: #fff;
	cursor: zoom-in;
}
.dashboard-keg-count-label {
	background: rgba(255, 255, 255, 0.2);
	padding: 5px;
	border-radius: 3px;
	margin: 2px;
}
.keg-count-label-wrapper {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	max-width: 400px;
	justify-items: center;
	align-items: center;
}
.keg-count-label {
	background: rgba(255, 255, 255, 0.2);
	padding: 5px;
	border-radius: 3px;
	margin: 2px;
}
.keg-count-label.cell-span {
	grid-column-start: 1;
	grid-column-end: 6;
}
.dashboard-client-link {
	color: rgba(0, 0, 0, 0.87) !important;
}
.dashboard-client-link:hover {
	color: #e5a03e !important;
	text-decoration: underline;
	cursor: pointer;
}
.dashboard-client-progress-wrapper .ui.progress {
	padding: 0;
	margin: 0 5px 0 0 !important;
}

.filter-table-bg {
	background: #fff;
}
.filter-table-text-center {
	text-align: center;
}
.archived {
	text-decoration: line-through;
}
.text-capitalize {
	text-transform: capitalize;
}
.ReactTable .-pagination .-btn {
	color: #fff !important;
	text-transform: uppercase;
	font-weight: 700;
	background: #667585 !important;
}

.ReactTable .multi-coupler-cell-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.ReactTable .multi-coupler-cell-container .ui.circular.label {
	align-self: center;
}

.ReactTable .rt-thead.-filters input {
	border: 1px solid #b8bbbf !important;
}
.ReactTable .rt-thead .rt-tr {
	background: #d6dce2;
}
.ReactTable .rt-thead.-filters {
	border-top: 1px solid #ccd2d8;
}
.ReactTable .rt-thead.-header {
	text-transform: uppercase;
	color: #1d1e20;
}

.no-records-found-illustration-wrapper {
	text-align: center;
}
.no-records-found-illustration-wrapper img {
	width: 80%;
}
.no-records-found-illustration-wrapper p {
	color: #8898aa;
	margin-bottom: 50px;
}
.react-autosuggest__suggestions-list {
	margin: 15px 0 0 0 !important;
	padding-inline-start: 0;
}

/* CHARTS ///////////////////////////////////////////////////////////////// */
.client-dashboard-graph {
	min-height: 300px !important;
}
/* GRIDS ////////////////////////////////////////////////////////////////// */
.grid-2-col {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.grid-3-col {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
.grid-4-col {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}
/* //////////////////////////////////////////////////////////////////////// */
@media all and (max-width: 1300px) {
	.report-wrapper {
		grid-template-columns: 1fr 250px;
	}
}
@media all and (max-width: 1100px) {
	.hide-on-medium {
		display: none;
	}
	.report-wrapper {
		grid-template-columns: 1fr;
	}
	.report-nav-drawer {
		grid-column: 1/1;
		grid-row: 1/1;
		min-height: 200px;
	}
	.report-page-wrapper {
		grid-column: 1/1;
		grid-row: 2/2;
	}
	.report-wrapper a {
		grid-template-columns: 1fr;
	}
	.report-wrapper svg {
		display: none;
	}
	.report-wrapper h3 {
		border-radius: 5px;
	}
	.report-nav-drawer a[aria-current='page']:after {
		border-right: none;
	}
}
@media all and (max-width: 900px) {
	.showMobile {
		display: block;
	}
	.widget h2 {
		text-align: center;
	}
	.app-container {
		grid-template-columns: 1fr;
	}
	.side-drawer .version,
	.side-drawer .product-of {
		display: none;
	}
	.side-drawer .main-nav.closed {
		max-height: 0;
		overflow: hidden;
	}
	.side-drawer .main-nav.open {
		display: block;
		max-height: 1000px;
	}
	.side-drawer .logo {
		text-align: left !important;
		padding: 8px 20px !important;
	}
	.side-drawer .mobile-nav-trigger {
		background: #e5a03e;
		border-bottom: 1px solid #e5a03e;
		border-left: 1px solid #e5a03e;
	}
	.side-drawer .mobile-nav-trigger:hover {
		background: rgba(0, 0, 0, 0.8);
	}
	.side-drawer .mobile-nav-trigger:hover .bars {
		color: #fff !important;
	}
	.side-drawer .mobile-nav-trigger .bars {
		color: #fff;
		margin: 15px 3px;
		font-size: 20px !important;
	}
	.side-drawer-header {
		display: grid;
		grid-template-columns: 1fr 65px;
		align-content: center;
	}
	.side-drawer-header .logo {
		text-align: left;
	}
	.mobile-nav-trigger {
		display: block;
		padding: 22px 18px;
	}
	.mobile-nav-trigger:hover {
		cursor: pointer;
	}
	.side-drawer .mobile-nav-trigger:hover .bars {
		color: var(--dark);
	}

	.dashboard-keg-status-drawer {
		grid-template-columns: 1fr;
	}
	.dashboard-keg-status-drawer .keg-status-card .description {
		display: none;
	}
	.dashboard-keg-status-drawer .keg-status-card .title {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	.app-container .ui.grid > [class*='three column'].row > .column {
		width: 100% !important;
		margin: 10px 0 !important;
	}
	.app-container .ui.grid > [class*='eleven wide'].column,
	.app-container .ui.grid > [class*='five wide'].column {
		width: 100% !important;
	}
	.ui.two.cards > .card {
		width: 100% !important;
	}
	.app-container .ui.grid > [class*='eight wide'].column {
		width: 100% !important;
	}
	.illustration {
		width: 90%;
		margin: 5%;
	}

	.inform-heading.start {
		margin: -10px 0 10px 0 !important;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.keg-fill-column-center .inform-heading {
		border-radius: 0;
		margin: 10px 0 !important;
	}
	.inform-heading.end {
		margin: 0 !important;
		border-radius: 0;
	}
	.keg-fill-column-center {
		border: none;
	}
	body .ui[class*='right labeled'].icon.button {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	body .ui[class*='right labeled'].icon.button .icon {
		display: none;
	}
	.keg-selector-page {
		padding: 30px 30px 110px 30px;
	}
	.keg-selector-page .ui.form .button.big {
		position: fixed !important;
		width: 100% !important;
		left: 0;
		padding: 25px 0;
		bottom: 0;
		z-index: 99999;
		opacity: 1 !important;
	}
	.keg-list-nav-drawer,
	.report-nav-drawer {
		grid-template-columns: 1fr;
	}
	.ui.table thead {
		display: none !important;
	}
	.ui.table tbody tr td {
		text-align: center;
	}
	.value {
		line-height: 25px;
		padding: 5px;
		font-size: 17px;
	}
	.countDispatched:after {
		content: 'Dispatched';
		font-size: 12px;
		color: #fff;
		background: #00b5ad;
		padding: 0 10px;
		border-radius: 5px;
		margin-left: 15px;
		width: 100px;
		display: inline-block;
	}
	.countWarehouse:after {
		content: 'In Warehouse';
		font-size: 12px;
		color: #fff;
		background: #b5cc18;
		padding: 0 10px;
		border-radius: 5px;
		margin-left: 15px;
		width: 100px;
		display: inline-block;
	}
	.labelEmptyKegs:after {
		content: 'Empty Kegs';
		font-size: 12px;
		color: #fff;
		background: #00b5ad;
		padding: 0 10px;
		border-radius: 5px;
		margin-left: 15px;
		width: 100px;
		display: inline-block;
	}
	.labelWarehouseKegs:after {
		content: 'Filled Kegs';
		font-size: 12px;
		color: #fff;
		background: #b5cc18;
		padding: 0 10px;
		border-radius: 5px;
		margin-left: 15px;
		width: 100px;
		display: inline-block;
	}

	.ui.modal > .image.content {
		grid-template-columns: 1fr !important;
	}
	.ui.modal .actions .button {
		display: block;
		width: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.ui.modal .content > .description {
		width: auto !important;
	}
	.ui.modal .content .slider-inner-content h3 {
		font-size: 23px !important;
	}
	.ui.modal .content .slider-inner-content p {
		font-size: 14px !important;
	}
	body .ui.grid > .row > [class*='eleven wide'].column,
	body .ui.grid > .row > [class*='five wide'].column {
		width: 100% !important;
	}
	body .ui.grid > [class*='two column'].row > .column {
		width: 100% !important;
		margin-bottom: 20px;
	}
	body .ui.grid > [class*='two column'].row > .column.fill {
		margin-bottom: 0;
	}
	.dashboard .basic.table .actions {
		text-align: center !important;
	}
	.dashboard .basic.table .value {
		font-size: 20px;
		font-weight: 700;
	}
	.ui.modal .content > .image {
		width: 80%;
		margin: 20px 20%;
	}
	.slider-inner-content {
		margin: 10px 10% 30px 10% !important;
	}
	.logged-in-user-details img.ui.image {
		display: none !important;
	}
	.app-header h1 {
		font-size: 20px !important;
	}
	.side-drawer {
		grid-template-rows: 1fr !important;
	}
	.app-container {
		grid-template-rows: auto 1fr !important;
	}
	.widget h3 {
		text-align: center;
		background: var(--grey-2);
		padding: 10px;
		border-radius: 5px;
	}
	.report-nav-drawer a svg {
		display: none;
	}
	.report-nav-drawer a h3 {
		border-radius: 5px;
	}
	.sub-drawer-header {
		background: #2b2c50;
	}
	.keg-quick-view-page .ui.grid > .row > [class*='six wide'].column,
	.keg-quick-view-page .ui.grid > .row > [class*='ten wide'].column {
		width: 100% !important;
	}
	.keg-quick-view-page.page-padding {
		padding-top: 50px;
	}
	.keg-quick-view-page .two-col-last {
		border-left: none !important;
	}
	.keg-quick-view-page .two-col-last h3 {
		margin: 0 !important;
		border-radius: 0;
	}
	.two-col-last .col-inner {
		padding: 20px 30px 20px 5px;
	}
	.keg-view-details .ui.table tbody tr td {
		text-align: left;
	}
}
@media all and (max-width: 768px) {
	.activity-log-list .content .header,
	.activity-log-list .content .description {
		float: none;
	}
	.center-on-mobile {
		text-align: center !important;
	}
}
@media all and (max-width: 480px) {
	.widget-table-header {
		grid-template-columns: 1fr;
		justify-items: center;
		grid-row-gap: 10px;
	}
	.widget-table-header h2 {
		text-align: center;
		justify-self: center;
	}
}
